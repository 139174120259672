import { Skeleton } from "@ui/components/data-display/skeleton/Skeleton";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";

const ProductCarouselSkeleton = () => {
    return (
        <Box w="full" mt="0.2" py={3}>
            <Flex justifyContent={"space-between"} alignItems="center" px={[4, 4, 4, 6]}>
                <Skeleton height="14px" width={[28, 60]} />
            </Flex>
            <Flex
                direction="row"
                gap={0.2}
                mt={3}
                pb={3}
                h={["auto", "100vw", "66vw", "48vw", "45vw"]}
                overflowX={"scroll"}
            >
                {Array(4)
                    .fill("")
                    .map((_, index) => (
                        <Flex key={index} w="100%">
                            <Box
                                width={"full"}
                                w={[
                                    "calc(100vw/1.3)",
                                    "calc(100vw/2.5)",
                                    "calc(100vw/2.5)",
                                    "calc(100vw/3.5)",
                                ]}
                            >
                                <Skeleton mb={4} sx={{ aspectRatio: ["4/5"] }} />
                                <Box px={5} py={3} gap={1}>
                                    <Skeleton height="21px" width={[28, 60]} mb={1} />
                                    <Skeleton height="21px" width={[20, 39]} mb={1} />
                                    <Skeleton height="21px" width={[10.4, 20]} />
                                </Box>
                            </Box>
                        </Flex>
                    ))}
            </Flex>
        </Box>
    );
};

export default ProductCarouselSkeleton;
